import Vue from 'vue';

import App from './boot/App.vue';
import router from './router';
import store from './store';

import './boot/registerServiceWorker';

// Vuetify
import vuetify from './boot/plugins/vuetify';

// Toasts
import './boot/plugins/toasted';

// Axios
import axios from 'axios';
Vue.prototype.$http = axios;

// ACL
import acl from './boot/plugins/acl';

// Global components
import './boot/globalComponents.js';

// Helpers
import helpers from './boot/plugins/helpers.js';
Vue.prototype.$helpers = helpers;

// Global constants
import { globals } from './boot/globalConstants.js';
Vue.prototype.$globals = globals;

// Кастомные директивы
import './boot/customDirectives.js';

// Фильтры
import './boot/filters.js';

// Кастомные глобальные стили
import './sass/custom.sass';

// Form validation
import {
  validationRules,
  errorMessages,
} from './boot/plugins/validationRules.js';
Vue.prototype.$validationRules = validationRules;
Vue.prototype.$errorMessages = errorMessages;

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  acl,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
