import qs from 'qs';
import router from '@/router';
import auth from '@/shared/auth';

export default {
  async signUp({ commit }, payload) {
    let { fields, roi_visit } = payload;
    try {
      let response = await this._vm.$http({
        method: 'POST',
        url: '/udata/users/sign_up/0/1/1/.json',
        data: qs.stringify({ data: { fields: fields } }),
      });
      let data = response.data;

      if (
        data.status === 'success' ||
        data.status === 'need_phone_verification'
      ) {
        await this._vm.$http({
          method: 'GET',
          url: `/udata/users/amo_contact_create/${roi_visit}/.json`,
        });

        if (data.exception) {
          console.log('SMS sender error: ', data.exception);
        }

        localStorage.setItem('userData', JSON.stringify(data.result));
        commit('SET_USER', data.result);

        return data;
      }
    } catch (err) {
      console.error('udata/users/sign_up error:', err);
      this.$toasted.error(err);
    }
    return false;
  },

  async signIn({ commit }, payload) {
    let data = await auth.signIn(payload);
    if (data.status === 'success') {
      localStorage.setItem('userData', JSON.stringify(data.result));
      commit('SET_USER', data.result);
      if (!payload.noRedirect) {
        router.push('/').catch(() => {});
      }
      return true;
    } else {
      commit('SET_USER');
      this._vm.$toasted.show('Авторизоваться не удалось', {
        type: 'warning',
      });
      return false;
    }
  },

  async signOut({ commit }, payload) {
    await auth.signOut();
    commit('SET_USER');
    if (!payload?.noRedirect) {
      router.push('/sign-in');
    }
  },

  async getCurrentUser({ commit }) {
    let user = await auth.getCurrentUser();
    localStorage.setItem('userData', JSON.stringify(user));
    commit('SET_USER', user);
    return user;
  },
};
