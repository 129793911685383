// Global (default Vuetify) validation rules
const validationRules = {
  phone: [
    (v) => !!v || 'Необходимо указать телефон',
    (v) => (v && v.length == 10) || 'Некорректный формат телефона',
  ],
  phoneOptional: [
    (v) => v.length == 0 || v.length == 10 || 'Некорректный формат телефона',
  ],
  email: [
    (v) => !!v || 'Необходимо указать e-mail',
    (v) => /.+@.+\..+/.test(v) || 'Введен некорректный e-mail',
  ],
  required: [
    (v) => {
      if (v instanceof Array) {
        return v.length > 0 || 'Поле обязательно для заполнения';
      }
      return !!v || 'Поле обязательно для заполнения';
    },
  ],
  number: [(v) => /^\d+$/.test(v) || 'Необходимо ввести число'],
  bik: [
    (v) => !!v || 'Поле обязательно для заполнения',
    (v) => (v && v.length == 9) || 'Некорректный формат бик',
  ],
  bankAccount: [
    (v) => !!v || 'Поле обязательно для заполнения',
    (v) => (v && v.length == 20) || 'Некорректный формат счета',
  ],
};

const errorMessages = {
  show(field) {
    const errors = [];
    if (!field.$dirty) return errors;
    field.$required === false && errors.push('Поле обязательно для заполнения');
    field.$phone === false && errors.push('Некорректный формат телефона');
    field.$email === false && errors.push('Введен некорректный e-mail');
    return errors;
  },
};

export { validationRules, errorMessages };
