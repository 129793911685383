import auth from '@/shared/auth';

export default {
  // NB!: Асинхронные свойства следует использовать, если необходимо повторно убедиться что пользователь авторизован
  // Если такой необходимости нет, то можно получать статус пользователя из state
  // И статус и ACL роль обновляются при каждом переходе/роутинге
  async isUserLoggedIn() {
    let user = await auth.getCurrentUser();

    let isAuthenticated = false;
    if (user.is_auth) {
      isAuthenticated = true;
    }
    return isAuthenticated;
  },

  async getUserId() {
    let user = await auth.getCurrentUser();
    return user.user_id;
  },
  // TODO remove - instead use async dispatch/actions and sync getters/state
};
