const helpers = {
  locale: 'ru-RU',

  showStandardResponseMessages(data, vm) {
    function showErrorMessage(text) {
      const croppedText =
        text.length <= 100 ? text : text.substring(0, 100).trim() + '...';
      vm.$toasted.show(croppedText, {
        type: 'error',
        duration: 0,
        action: [
          {
            icon: 'mdi mdi-content-copy',
            class: 'white--text ma-0 pl-2',
            onClick: () => {
              navigator.clipboard.writeText(text);
            },
          },
          {
            icon: 'mdi mdi-close',
            class: 'white--text ma-0 pl-2',
            onClick: (_, toastObject) => {
              toastObject.goAway(0);
            },
          },
        ],
      });
    }

    // TODO Добавить возможность отправлять несколько сообщений одного типа (text -> array)
    if (data.message && typeof data.message === 'object') {
      Object.entries(data.message).forEach(([type, text]) => {
        if (type === 'error') {
          showErrorMessage(text);
        } else {
          vm.$toasted.show(text, {
            type: type,
          });
        }
      });
    }

    let text;
    if (data.error) text = data.error.message;
    if (data.udata?.error) text = data.udata.error;
    if (data.type === 'Error') text = data.message;
    if (text) {
      showErrorMessage(text);
    }
  },

  // TIME FORMATTING
  formatDateTime(date) {
    if (!date) return '';
    let dateObj = new Date(date);
    return (
      dateObj.toLocaleTimeString(this.locale) +
      ' ' +
      dateObj.toLocaleDateString(this.locale)
    );
  },
  formatDate(date, options) {
    if (!date) return '';
    let dateObj = new Date(date);
    return dateObj.toLocaleDateString(this.locale, options);
  },

  async download(filename, src = null, id = null, vm) {
    try {
      const url = '/files/?' + (src !== null ? 'src=' + src : 'id=' + id);
      const response = await vm.$http({
        method: 'GET',
        url: url,
        responseType: 'blob',
      });
      this.downloadStream([response.data], filename);
    } catch (err) {
      console.error('download error:', err);
      vm.$toasted.error(err);
    }
  },
  downloadStream(data, filename) {
    const blob = new Blob(data);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.target = '_blank';
    link.click();
    link.remove();
  },

  ymReachGoal(goal) {
    /* eslint-disable */
    ym(67163572, 'reachGoal', goal);
    /* eslint-enable */
  },
};

export default helpers;
