import '@mdi/font/css/materialdesignicons.css';

import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

import ru from 'vuetify/es5/locale/ru';

export default new Vuetify({
  lang: { locales: { ru }, current: 'ru' },
  theme: {
    themes: {
      light: {
        primary: '#14213D',
        secondary: '#E5E5E5',
        accent: {
          base: '#FCA311',
          lighten1: '#fcb239',
          lighten2: '#fdc160',
          lighten3: '#fdd087',
          lighten4: '#fee0af',
          lighten5: '#feefd6',
        },
        error: '#f44336',
        warning: '#ff5722',
        info: '#607d8b',
        success: '#4caf50',
      },
    },
    options: {
      customProperties: true,
    },
  },
  breakpoint: {
    mobileBreakpoint: 'xs',
  },
  icons: {
    iconfont: 'mdi',
  },
});
